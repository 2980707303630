<script lang="tsx">

import { h, ref, defineComponent } from 'vue';

export default defineComponent({
	emits: ['update:modelValue', 'setpage'],

	props: {
		modelValue: {
			type: Number,
			default: 0
		},
		pages: {
			type: Number,
			default: 10
		},
		countShowPages: {
			type: Number,
			default: 5
		}
	},

	watch: {
		modelValue(value) {
			this.thisPage = value;
		}
	},

	setup(props: any, { emit }: { emit: any }) {
		const thisPage = ref<number>(props.modelValue);

		const setPage = (page: number) => {
			emit('setpage', thisPage.value = page);
			emit('update:modelValue', thisPage.value = page);
		}

		return {
			thisPage,
			setPage
		}
	},

	render() {
		if (this.pages < 2) return null;

		const buttons = [];

		let left = this.thisPage;
		let start = (left < Math.floor(this.countShowPages / 2)) ? 0 : this.thisPage - Math.floor(this.countShowPages / 2);
		let end = start + this.countShowPages;

		if (end > this.pages) {
			start -= (end - this.pages);
			end = this.pages;
			if (start < 0) start = 0;
		}

		if (this.pages > 2)
			buttons.push(h('li', { class: this.thisPage == 0 ? 'page-item disabled' : 'page-item' },
				h('a', {
					href: '#',
					onClick: (event: any) => {
						event.preventDefault();
						this.setPage(0);
					},
					class: 'page-link'
				}, <i class="icon icon-chevrons-left" > </i>)
			));

		buttons.push(h('li', { class: this.thisPage == 0 ? 'page-item disabled' : 'page-item' },
			h('a', {
				href: '#',
				onClick: (event: any) => {
					event.preventDefault();
					this.setPage(this.thisPage - 1);
				},
				class: 'page-link'
			}, <i class="icon icon-chevron-left" > </i>)
		));

		for (let i = start; i < end; i++) {
			buttons.push(h('li', { class: i == this.thisPage ? 'page-item active' : 'page-item' },
				h('a', {
					href: '#',
					onClick: (event: any) => {
						event.preventDefault();
						this.setPage(i)
					},
					class: 'page-link'
				}, i + 1)
			));
		}

		buttons.push(h('li', { class: this.thisPage == this.pages - 1 ? 'page-item disabled' : 'page-item' },
			h('a', {
				href: '#',
				onClick: (event: any) => {
					event.preventDefault();
					this.setPage(this.thisPage + 1);
				},
				class: 'page-link'
			}, <i class="icon icon-chevron-right" > </i>)
		));

		if (this.pages > 2)
			buttons.push(h('li', { class: this.thisPage == this.pages - 1 ? 'page-item disabled' : 'page-item' },
				h('a', {
					href: '#',
					onClick: (event: any) => {
						event.preventDefault();
						this.setPage(this.pages - 1);
					},
					class: 'page-link'
				}, <i class="icon icon-chevrons-right" > </i>)
			));

		return h('ul', { class: "paginationtable" }, buttons);
	}
})
</script>

<style>
</style>
