<template>
	<slot v-if="visible"></slot>
</template>

<script>
export default {
	props: {
		caption: {
			type: String,
			default: null
		},
		visible: {
			type: Boolean,
			default: true
		},
		badge: {
			type: String,
			default: null
		}
	}
}
</script>

<style>
</style>
