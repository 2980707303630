<script lang="jsx">
import { h } from 'vue';

export default {
	props: {
		form: {
			type: Object,
			default: null
		},
		field: {
			type: String,
			default: ''
		},
		validation: {
			type: Boolean,
			default: true
		},
		id: {
			type: String,
			default: ''
		}
	},

	setup(props, { slots }) {
		const field = props.form.store.state.fields[props.field];

		if (!field) {
			console.error(`DBCaption: Не найдено поле(${props.field})!`);

			return () => null
		}

		return () => {
			const elements = [slots.default ? slots.default() : field.description, ':'];

			if (props.validation && field.validation && Object.keys(field.validation).includes('isRequired')) elements.push(<em class="error help-block">*</em>);

			return h('label', { class: 'col-form-label' }, elements);
		}
	}
}
</script>

<style></style>