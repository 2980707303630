import { createToast } from 'mosha-vue-toastify';

export function toastError(message: string) {
	createToast(
		message,
		{
			toastBackgroundColor: '#f44336',
			timeout: 2000,
			hideProgressBar: true
		}
	);
}

export function toastSuccess(message: string) {
	createToast(
		message,
		{
			toastBackgroundColor: '#409444',
			timeout: 2000,
			hideProgressBar: true
		}
	);
}