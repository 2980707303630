<template>
	<tfoot>
		<tr>
			<th v-if="config.menu" style="width: 20px;"></th>
			<th v-if="config.rowSelect" style="width: 25px;"></th>
			<th v-if="config.numbering" style="width: 40px;"></th>
			<template v-for="(field, fieldkey) of store.state.fields" :key="fieldkey">
				<template v-if="visible(field)">
					<th :style="field.config.footerStyle ? field.config.footerStyle : null">{{ controller.footerData[fieldkey] ? controller.footerData[fieldkey] : '' }}</th>
				</template>
			</template>
		</tr>
	</tfoot>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
	emits: ['sort'],

	props: {
		controller: {
			type: Object,
			default: () => ({})
		}
	},


	setup(props: any) {
		const store = props.controller.store;

		const config = props.controller.settings;

		const visible = (field: any) => {
			const config = field?.config;

			return (typeof config.visible == 'function' ? config.visible() : config.visible) && !config.hide;
		}

		return {
			config,
			store,
			visible
		}
	}
})
</script>

<style></style>
	