import { genGUID } from "@/helpers/utils";
import { reactive, ref, watch } from "vue";

export default class TreeController {
	public tree: any;
	public active: any;
	public selectid: any;

	constructor(protected props: any, protected emit: any) {
		this.tree = ref([]);
		this.active = null;

		this.selectid = ref(null);

		watch(this.selectid, (newValue: any) => {
			const node = this.findNode(newValue);
			if (node) this.openParents(node);
		})
	}

	openParents(node: any) {
		if (node.parent) {
			node.parent.expanded = true;

			this.openParents(node.parent);
		}
	}

	deleteNode(id: any, items = this.tree.value) {
		let result: any = null;

		for (const i in items) {
			if (items[i].id == id) return items.splice(i, 1)[0];

			if (!result && items[i].children) result = this.deleteNode(id, items[i].children);
		}

		return result;
	}

	findNode(id: any, nodes = this.tree.value) {
		let result: any = null;

		for (const i in nodes) {
			if (nodes[i].id == id) return nodes[i];

			if (!result && nodes[i].children) result = this.findNode(id, nodes[i].children);
		}

		return result;
	}

	createNode(item: any, nodes: any, parent = null) {
		const node: any = reactive({
			id: this.props.idprop && item[this.props.idprop] ? item[this.props.idprop] : genGUID(),
			text: item[this.props.textprop],
			data: item,
			parent,
			folder: !!item.children || item.node,
			isEditing: false,
			icon: this.props.iconprop && item[this.props.iconprop] ? item[this.props.iconprop] : 'icon icon-minus',
			loaded: false
		})

		if (node.folder) {
			node.expanded = false;
			node.children = [];

			if (item?.children?.length > 0) {
				this.createTree(item.children, node.children, node);

				node.loaded = true;
			}
		}

		node.setData = (data: any) => {
			Object.assign(node.data, data);
			if (this.props.textprop in node.data) {
				node.text = node.data[this.props.textprop];
				if (node.data['icon']) node.icon = node.data['icon'];
			}
		}

		node.elements = () => node.parent ? node.parent.children : this.tree.value;

		node.index = () => {
			const result = -1;

			const elements = node.elements();

			for (const i in elements) if (elements[i].id == node.id) return Number(i);

			return result;
		}

		nodes.push(node);

		return node;
	}

	loadNodeData(node: any, items: any) {
		node.children = [];

		this.createTree(items, node.children, node);
	}

	createTree(items: any, nodes = this.tree.value, parent = null) {
		for (const item of items) {
			this.createNode(item, nodes, parent);
		}
	}

	isChild(parent: any, id: any) {
		const _isChild = (item: any) => {
			if (item.id == id) return true;

			if (item.children) {
				for (const child of item.children)
					if (_isChild(child)) return true;
			}

			return false;
		}

		return _isChild(parent);
	}

	setActive = (node: any) => {
		//Открывать папки
		if (this.selectid.value != node.id) {
			this.active = node;
			this.selectid.value = node.id;
			this.emit('node:active', node);
		}
	}

	startEditing() {
		if (this.active.isEditing) return

		this.active.isEditing = true;
	}

	stopEditing(newText: any) {
		if (!this.active.isEditing) return

		this.active.isEditing = false

		const prevText = this.active.text

		if (newText && newText !== false && this.active.text.value !== newText) {
			this.active.text = newText;
			this.active.data[this.props.textprop] = newText;

			this.emit('text:changed', newText, prevText, this.active)
		}
	}

	onKeydown(event: any) {
		// console.log(event.keyCode);
		switch (event.keyCode) {
			case 13:
				this.emit('node:dblclick', this.active);

				break;
			case 27:
				if (this.active.isEditing) this.active.isEditing = false;

				break;

			case 46://delete
				this.emit('node:delete', this.active);

				break;
			case 113:
				this.startEditing();

				break;
			case 37://left
				event.preventDefault();

				if (this.active.folder && this.active.expanded) {
					this.active.expanded = false;
				} else if (this.active.parent) {
					this.setActive(this.active.parent);
				}

				break;
			case 39://right
				event.preventDefault();

				if (this.active.folder) {
					if (this.active.expanded) {
						if (this.active.children.length > 0) this.setActive(this.active.children[0]);
					} else {
						this.emit('node:open', this.active, this);
						this.active.expanded = true;
					}
				}

				break;
			case 38://up
				event.preventDefault();
				{
					const index = this.active.index();

					const children = this.active.elements();
					if (index > 0) {
						if (children[index - 1].folder && children[index - 1].expanded) {
							const childrenA = children[index - 1].children;

							this.setActive(childrenA[childrenA.length - 1]);
						} else {
							this.setActive(children[index - 1]);
						}
					} else {
						if (this.active.parent) {
							const indexA = this.active.parent.index();
							const childrenA = this.active.parent.elements();
							this.setActive(childrenA[indexA]);
						}
					}
				}

				break;
			case 40://down
				event.preventDefault();
				{
					const index = this.active.index();

					const children = this.active.elements();
					if (this.active.folder && this.active.expanded) {
						if (this.active.children.length > 0) this.setActive(this.active.children[0]);
					} else {
						if (index < children.length - 1) {
							this.setActive(children[index + 1]);
						} else if (this.active.parent) {
							const indexA = this.active.parent.index();
							const childrenA = this.active.parent.elements();
							if (indexA < childrenA.length - 1) this.setActive(childrenA[indexA + 1]);
						}
					}
				}

				break;
		}
	}
}