<template>
	<thead class="light-thead">
		<tr @contextmenu="onOpenMenu">
			<th v-if="config.menu" style="width: 20px; text-align: center;">
				<a href="#" @click="onOpenMenu">
					<i class="icon icon-menu"></i>
				</a>
			</th>
			<th v-if="config.rowSelect" style="width: 25px; text-align: center;">
				<input type="checkbox" class="row-checkbox" @click="selectRows" :indeterminate="store.data.rowsSelected === null" :checked="store.data.rowsSelected" />
			</th>
			<th v-if="config.numbering" style="width: 40px; text-align: center;">№</th>
			<template v-for="(field, fieldkey) of store.state.fields" :key="fieldkey">
				<template v-if="visible(field)">
					<th :style="getStyleTh(field)" :class="getClassTh(fieldkey)" @click="(event) => onClickCell(event, fieldkey, field)" v-html="field.description"></th>
				</template>
			</template>
		</tr>
	</thead>
</template>

<script lang="ts">
import openContextMenu from '@/components/openContextMenu'

import { defineComponent, nextTick } from 'vue'

export default defineComponent({
	emits: ['sort'],

	props: {
		controller: {
			type: Object,
			default: () => ({})
		},
		checkedRowSelect: {
			type: Boolean,
			default: false
		}
	},


	setup(props: any, { emit }: { emit: any }) {
		const store = props.controller.store;

		const config = props.controller.settings;

		const sortingFields: any = props.controller.sortingFields;

		/**
		 * Открытие контекстного меню
		 */
		const onOpenMenu = (event: any) => {
			if (config.menu) {
				event.preventDefault();

				openContextMenu(event, props.controller.contextMenuHead());
			}
		}

		/**
		 * Обработка события клика по полям заголовка
		 */
		const onClickCell = (event: any, field: any, fieldConfig: any) => {
			event.preventDefault();

			if (config.sorting && 'sorting' in fieldConfig?.config) {
				if (!event.ctrlKey) {
					for (const key in sortingFields) {
						if (key != field) {
							delete sortingFields[key];
						}
					}
				}

				if (field in sortingFields) {
					if (sortingFields[field]) {
						sortingFields[field] = false;
					} else {
						delete sortingFields[field];
					}
				} else {
					sortingFields[field] = true;
				}

				emit('sort', sortingFields);
			}
		}

		/**
		 * Стили для ячеек заголовка
		 */
		const getStyleTh = (fieldConfig: any) => {
			const result: any = {};

			if (fieldConfig.config?.width) {
				result['width'] = fieldConfig.config.width;
			}

			return result;
		}

		/**
		 * Класс ячейки заголовка по умолчанию
		 */
		const getClassTh = (field: any) => {
			const fieldConfig = store.state.fields[field];

			const _config = fieldConfig?.config;

			if (config.sorting && _config.sorting) {
				return field in sortingFields ? (sortingFields[field] ? { sorting_asc: true } : { sorting_desc: true }) : { sorting: true };
			} else {
				return {};
			}
		}

		const selectRows = (e: any) => {
			if (e.target.checked && store.data.rowsSelected !== null) {
				store.forEach((row: any) => config.onRowSelect(row, true));
			} else {
				store.forEach((row: any) => config.onRowSelect(row, false));
			}

			nextTick(() => {
				if (store.data.rowsSelected === null) {
					e.target.indeterminate = true;
				} else {
					e.target.checked = store.data.rowsSelected;
				}
			})
		}

		const visible = (field: any) => {
			const config = field?.config;

			return (typeof config.visible == 'function' ? config.visible() : config.visible) && !config.hide;
		}

		return {
			config,
			store,
			sortingFields,
			getStyleTh,
			getClassTh,
			onClickCell,
			onOpenMenu,
			selectRows,
			visible
		}
	}
})
</script>

<style scoped>
.row-checkbox {
	position: relative;
	left: 0px;
	opacity: 1;
	top: 3px;
}

.line-through {
	text-decoration: line-through;
}
</style>
	