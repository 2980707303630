export default {
	forms: {
		edit: {
			panel: {
				width: '80%'
			},
			config: {
				fields: {
					id: {
						config: {
							visible: false
						}
					},
					name: {},
					input_description: {}
				}
			}
		},
		list: {
			config: {
				fields: {
					id: {
						config: {
							visible: false
						}
					},
					name: {},
					input_description: {}
				}
			}
		},
		select: {
			panel: {
				width: '80%'
			},
			config: {
				fields: {
					id: {
						config: {
							visible: false
						}
					},
					name: {}
				}
			}
		}
	}
}