import { computed, ref } from "vue";
import DBStoreRecord from "@/core/db_store_record";

export default class DBQuestionaryController {
	public form: any;
	public store: DBStoreRecord;
	public data: any;

	public structure: any;
	public structureField: any;
	public field: any;

	public label: string;
	public access: boolean;
	public config: any;
	public readonly: any;

	public required: any;
	public verified: any;
	public valid: any;
	public feedback: any;
	public message: any;

	constructor(public props: any) {
		this.field = ref(props.field);
		this.form = props.form;
		this.label = '';
		this.store = props.form.store;
		this.data = this.store.data;
		this.access = false;

		this.verified = ref<boolean>(false);
		this.valid = ref<boolean>(false);
		this.feedback = ref<string>('');
		this.message = ref<string>('');
		this.required = ref<boolean>(false);

		this.readonly = computed(
			() => {
				if (this.access) {
					return typeof props.readonly == 'boolean'
						? props.readonly
						: props.form.store.state.readonly || props.form.readonly.value || this.field.value == props.form.store.model.key || this.structure.sql
				} else {
					return true;
				}
			}
		)

		this.init();
	}

	get value() {
		return this.store.getValue(this.field.value);
	}

	set value(value: any) {
		this.store.setValue(this.field.value, value);
	}

	/**
	 * Метод валидации
	 * @returns 
	 */
	validation() {
		if (this.props.validation && this.structure.validation) {
			for (const i in this.structure.validation) {
				const result = this.structure.validation[i](this.store.getValue(this.field.value, false), this.data);

				if (result !== false) {
					this.verified.value = true;
					this.valid.value = false;
					this.feedback.value = result;

					return result;
				}
			}
		}

		this.verified.value = false;
		this.valid.value = true;
		this.feedback.value = '';

		return false;
	}

	getFieldModel(fields: any, structure: any): any {
		const field: any = fields.shift();

		if (fields.length > 0) {
			return this.getFieldModel(fields, structure.fields[field].model);
		} else {
			return structure.fields[field];
		}
	}

	init() {
		const field = this.field;

		this.structure = this.getFieldModel(field.value.split('.'), this.store.state);

		if (this.structure) {
			this.label = typeof this.props.label == 'boolean' ? this.structure.description : this.props.label;

			this.structureField = this.structure?.type?.fields ? this.getFieldModel(field.value.split('.'), this.store.state) : null;

			if (this.structureField) {
				if (this.value) {
					field.value = this.value;

					this.structure = this.getFieldModel(field.value.split('.'), this.store.state);
				}

				if (typeof this.structureField?.access == 'boolean') {
					this.access = this.structureField.access;
				} else {
					this.access = this.structureField?.access ? this.store.availabeRole(this.structureField.access, field.value, this.structureField, this.data) : true;
				}
			} else {
				if (typeof this.structure?.access == 'boolean') {
					this.access = this.structure.access;
				} else {
					this.access = this.structure?.access ? this.store.availabeRole(this.structure.access, field.value, this.structure, this.data) : true;
				}
			}

			this.config = this.structure?.config ? this.structure.config : {};
		}
	}
}
