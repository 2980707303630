export default {
	forms: {
		list: {
			config: {
				fields: {
					author: {},
					authorname: {},
					recipient: {},
					recipientname: {},
					message: {},
					readed: {},
					route: {},
					created_at: {},
					updated_at: {}
				}
			}
		},
		edit: {
			config: {
				fields: {
					channel: {},
					author: {},
					avatar: {},
					authorname: {},
					recipient: {},
					recipientavatar: {},
					recipientname: {},
					message: {},
					readed: {},
					route: {},
					created_at: {},
					updated_at: {}
				}
			}
		}
	}
}