import { defineAsyncComponent } from 'vue';

export default {
	forms: {
		edit: {
			panel: {
				width: '80%'
			},
			component: defineAsyncComponent(() => import('./forms/Edit.vue'))
		},
		list: {
			component: defineAsyncComponent(() => import('./forms/List.vue'))
		},
		select: {
			panel: {
				width: '70%',
				// height: '500px'
			},
			component: defineAsyncComponent(() => import('./forms/Select.vue'))
			// config: {
			// 	fields: {
			// 		name: {
			// 		}
			// 	}
			// }
		}
	}
}