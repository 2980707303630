<template>
	<div class="form-group">
		<label v-if="label">{{ controller.label }}</label>
		<div :class="important ? 'me-border-important' : 'me-border'">
			<div class="textarea-field" style="margin-bottom: 1px;" v-for="(field, key) of fields" :key="key">
				<textarea
					:value="controller.getValue(key)"
					@input="(e) => controller.setValue(key, e.target.value)"
					type="text"
					:placeholder="field ? field : ''"
					class="form-control textarea textarea-menu"
					style="height: 15px;"
					rows="1"
					:ref="el => { if (el) controller.inputs[key] = el }" />

				<div class=" textarea-btn-bg-important">
					<button type="button" class="btn textarea-btn-menu" title="Шаблоны" @click="() => controller.onOpenMenuTextarea(key + 1)">
						<i class="icon icon-chevron-down"></i>
					</button>

					<DropDownMenu :controller="controller" v-if="controller.menuShow.value == key + 1"></DropDownMenu>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import { defineComponent, onMounted } from 'vue';

import DBMultiEditController from './controller';
import DropDownMenu from './drop-down-menu.vue';

export default defineComponent({
	components: {
		DropDownMenu
	},

	props: {
		form: {
			type: Object,
			default: null
		},
		field: {
			type: String,
			default: ''
		},
		fields: {
			type: Array,
			default: () => ([])
		},
		label: {
			type: [Boolean, String],
			default: true
		},
		important: {
			type: Boolean,
			default: false
		}
	},

	setup(props) {
		const controller = new DBMultiEditController(props);

		if (!controller.structure) {
			console.error(`DBMultiEdit: Не найдено поле(${controller.field.value})!`);

			return () => null
		}

		onMounted(() => {
			props.form.setField(controller);

			controller.updateTextareaHeight();
		})

		return {
			controller
		}
	}
})
</script>

<style>
.me-border {
	padding: 2px 2px 1px 2px;
	border: 1px solid #ddd;
	border-radius: 0.1875rem;
	box-shadow: 0 0 0 0 transparent;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.me-border-important {
	padding: 2px 2px 1px 2px;
	border: 2px solid #26a69a;
	border-radius: 0.1875rem;
	box-shadow: 0 0 0 0 transparent;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>
