import { defineAsyncComponent } from 'vue';

export default {
	forms: {
		edit: {
			config: {
				fields: {
					id: {
						config: {
							visible: false
						}
					},
					code: {},
					region: {},
					district: {},
					village_council: {},
					category_ate: {},
					name: {},
					name_full: {
					}
				}
			},
			component: defineAsyncComponent(() => import('./forms/Edit.vue'))
		},
		list: {
			config: {
				fields: {
					id: {
						config: {
							hide: true
						}
					},
					code: {},
					region: {},
					district: {},
					village_council: {},
					category_ate: {},
					name: {}
				}
			},
			component: defineAsyncComponent(() => import('./forms/List.vue'))
		},
		select: {
			panel: {
				width: '80%'
			},
			config: {
				fields: {
					id: {
						config: {
							hide: true
						}
					},
					code: {},
					region: {},
					district: {},
					village_council: {},
					category_ate: {},
					name: {
						config: {
							hide: true
						}
					},
					name_full: {
						description: 'Наименование'
					}
				}
			}
		}
	}
}