<template>
	<div class="modal" tabindex="-1" style="display: block">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Время истекает</h5>
				</div>

				<div class="modal-body">
					<p>Время сессии истекает. Продолжить соединение?</p>
				</div>

				<div class="modal-footer">
					<button type="button" class="btn btn-action" @click="proceed">Продолжить</button>
					<button type="button" class="btn btn-close" @click="logout">Выход ({{ timeStr }})</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import dayjs from "dayjs";

export default {
	data() {
		return {
			timeout: 0
		};
	},

	computed: {
		timeStr() {
			return dayjs(this.timeout * 1000).format("mm:ss");
		}
	},

	mounted() {
		this.timer = setInterval(() => {
			const date = new Date;

			this.timeout = ~~((this.$store.state.user.exp - date.getTime()) / 1000);

			if (this.timeout < 1) this.logout();
		}, 1000);
	},

	unmounted() {
		clearInterval(this.timer);
	},

	methods: {
		logout() {
			this.$store.dispatch("logout");
		},

		proceed() {
			this.$store.dispatch("proceedToken");
		}
	}
};
</script>
