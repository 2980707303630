import { defineAsyncComponent, defineComponent } from 'vue';

export default defineComponent({
	forms: {
		edit: {
			panel: {
				width: '90%'
			},
			component: defineAsyncComponent(() => import('./Edit/index.vue'))
		},
		list: {
			component: defineAsyncComponent(() => import('./List/index.vue'))
		},
		select: {
			panel: {
				width: '70%',
				height: '500px'
			},
			config: {
				fields: {
					status: {
					},
					name: {
					},
					brigade: {
					},
					call_type: {
					},
					department: {
					}
				}
			},
			component: defineAsyncComponent(() => import('./Select/index.vue'))
		}
	}
})