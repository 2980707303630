const server = process.env.APP_SERVER || "103.smartmed.by";

const config = {
	server: `https://${server}`,
	api: `https://${server}/api`,
	ws: `wss://${server}/ws/`,
	ws_local: `ws://localhost:7777`
};

export { config };
