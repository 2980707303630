import { defineAsyncComponent } from 'vue';

export default {
	forms: {
		edit: {
			component: defineAsyncComponent(() => import('./forms/Edit.vue'))
		},
		list: {
			component: defineAsyncComponent(() => import('./forms/List.vue'))
		},
		select: {
			panel: {
				width: '60%'
			},
			config: {
				fields: {
					id: {
						config: {
							visible: false
						}
					},
					region: {
					},
					district: {
					},
					village: {
					},
					locality: {
					},
					street: {
					},
					organization: {
					},
					department: {
					},
					lpu_organization: {
					},
					lpu_child: {
					},
					area: {
					}
				}
			}
		}
	}
}