//Директива нужна чтоб запретить закрытие выпадающей панели если нажали не на элементе выбора
//Необходимо ее установить на выпадающей панели
export default {
	mounted(el: any, binding: any) {
		if (binding.value) el.addEventListener("click", binding.value);
	},

	unmounted(el: any, binding: any) {
		if (binding.value) el.removeEventListener("click", binding.value);
	}
}