<script>
import { defineComponent, h } from 'vue'

export default defineComponent({
	props: {
		items: {
			type: Array,
			default: () => ([])
		},
		left: {
			type: Boolean,
			default: false
		}
	},

	setup(props) {
		const createDropdownMenu = (items) => {
			const result = [];

			for (const item of items) {
				if (item) {
					const elements = [];

					if (item.caption == '-') {
						result.push(h('div', { class: 'dropdown-divider' }));
					} else {
						if (item.icon) elements.push(item.icon);
						if (item.caption) elements.push(h('div', { class: 'cutoff', style: { 'width': '400px' } }, item.caption));

						if (item.items) {
							result.push(
								h(
									'div',
									{
										class: {
											'dropdown-submenu': true,
											'dropdown-submenu-left': props.left
										}
									},
									[
										h('a', { href: "#", class: "dropdown-item" }, elements),
										h('div', { class: "dropdown-menu" }, createDropdownMenu(item.items))
									]
								)
							);
						} else {
							const onClick = (e) => {
								e.preventDefault();

								item.onClick(e);
							}

							const params = {
								href: '#',
								class: 'dropdown-item dropdown-width',
								title: item.title ? item.title : null,
								onClick: item.onClick ? onClick : null
							}

							result.push(h('a', params, elements));
						}
					}
				}
			}

			return result;
		}

		return () => createDropdownMenu(props.items);
	}
})
</script>

