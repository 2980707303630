<script>
import { h } from 'vue';
import DBLabelController from './controller';

export default {
	props: {
		form: {
			type: Object,
			default: null
		},
		field: {
			type: String,
			default: ''
		}
	},

	setup(props) {
		const controller = new DBLabelController(props);

		if (!controller.structure) {
			console.error(`DBLabel: Не найдено поле(${controller.field.value})!`);

			return () => null
		}

		return () => h('label', {}, controller.value);
	}
}
</script>

<style>
</style>