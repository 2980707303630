import { fetchMainMenu } from "@/layouts/layouts";

import WS from '@/core/wsclient';
import ws_agent from '@/core/ws_agent';

import router from "@/router";

import { loadSchema } from '@/tables';
import { toastSuccess } from '@/helpers/toastify';
import { schema } from '@/api/db';
import { logout, proceedToken, status } from '@/api/auth';
import { constants } from '@/api/system';
import { list } from '@/api/equipments';
import { messageDelete, messageRead } from '@/api/messages';

export default {
	async login({ commit }: { commit: any }, { token, user }: { token: any, user: any }) {
		if (user.exp) {
			const date = new Date;

			user.exp = date.getTime() + user.exp * 1000;
		}

		commit('token', token);
		commit('timeout', 0);
		commit('user', user);

		commit('loaded', false);

		await fetchMainMenu();

		const resConstants: any = await constants();
		commit('constants', resConstants);

		const resSchema: any = await schema();
		loadSchema(resSchema);
		commit('loaded', true);

		const resEquipment: any = await list();
		commit('equipment', resEquipment);
		if (resEquipment.length > 0) ws_agent.open();

		router.push("/");

		WS.send({ event: 'login', data: token });
	},

	async status({ commit, state }: { commit: any, state: any }) {
		const response: any = await status();
		commit('loaded', false);

		if (response.exp) {
			const date = new Date;

			response.exp = date.getTime() + response.exp * 1000;
		}

		if (!response.id && state.token) commit('token', null);

		commit('user', response);

		await fetchMainMenu();

		const resConstants: any = await constants();
		commit('constants', resConstants);

		const resSchema: any = await schema();
		loadSchema(resSchema);
		commit('loaded', true);

		const resEquipment: any = await list();
		commit('equipment', resEquipment);
		if (resEquipment.length > 0) ws_agent.open();

		WS.send({ event: 'login', data: state.token });

		return response;
	},

	async logout({ commit }: { commit: any }) {
		const response: any = await logout();

		commit('loaded', false);
		commit('token', null);
		commit('timeout', 0);

		await router.push("/");

		await fetchMainMenu();

		const resSchema: any = await schema();
		loadSchema(resSchema);
		commit('loaded', true);

		commit('user', response);

		WS.send({ event: 'logout' });
	},

	async proceedToken({ commit, state }: { commit: any, state: any }) {
		const response: any = await proceedToken();
		if (response.token) {
			if (response.exp) {
				const date = new Date;

				response.exp = date.getTime() + response.exp * 1000;
			}

			commit('timeout', 0);
			commit('token', response.token);
			commit('user', Object.assign(state.user, { exp: response.exp }));
		}
	},

	async messagesRead({ commit }: { commit: any }) {
		const response: any = await messageRead();

		commit('messages', response);
	},

	async messagesDelete({ state }: { state: any }, id: any) {
		for (const i in state.messages) {
			if (state.messages[i].id == id) {
				await messageDelete(id);

				state.messages.splice(i, 1);

				break;
			}
		}
	},

	newMessage({ state }: { state: any }, message: any) {
		toastSuccess(message.message);

		state.messages.unshift(message);
	},

	online({ commit }: { commit: any }, value: any) {
		commit('online', value);
	},

	onlineAgent({ commit }: { commit: any }, value: any) {
		commit('onlineAgent', value);
	},

	cbPhoneNumber({ commit }: { commit: any }, value: any) {
		commit('cbPhoneNumber', value);
	},

	equipment(commit: any, value: any) {
		commit('equipment', value);
	}
}
