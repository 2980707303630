import { defineAsyncComponent } from 'vue';

export default {
	forms: {
		edit: {
			config: {
				fields: {
					action: {},
					user: {},
					document_type: {},
					owner: {},
					data: {},
					created_at: {}
				}
			},
			component: defineAsyncComponent(() => import('./forms/Edit.vue'))
		},
		list: {
			config: {
				fields: {
					action: {},
					user: {},
					document_type: {},
					owner: {},
					created_at: {}
				}
			},
			component: defineAsyncComponent(() => import('./forms/List.vue'))
		}
	}
}