import { Token } from "./Token";

class LocalStorageToken extends Token {
	constructor(private key: any) {
		super(key);

		this.key = key;
	}

	get() {
		return localStorage.getItem(this.key);
	}

	set(token: any) {
		localStorage.setItem(this.key, token);

		return this;
	}

	clear() {
		localStorage.removeItem(this.key);

		return this;
	}
}

export default LocalStorageToken;
