import { defineAsyncComponent } from 'vue';

export default {
	forms: {
		edit: {
			panel: {
				// height: '280px',
				width: '70%'
			},
			component: defineAsyncComponent(() => import('./forms/Edit.vue'))
		},
		list: {
			component: defineAsyncComponent(() => import('./forms/List.vue'))
		},
		select: {
			panel: {
				width: '90%',
				height: '500px'
			},
			config: {
				fields: {
					id: {
						config: {
							visible: false
						}
					},
					organization: {},
					department: {},

					time_start: {
					},
					time_end: {
					}
				}
			}
		}
	}
}