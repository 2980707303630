import { defineAsyncComponent } from 'vue';

export default {
	forms: {
		edit: {
			panel: {
				// height: '280px',
				width: '50%'
			},
			component: defineAsyncComponent(() => import('./forms/Edit.vue'))
		},
		list: {
			component: defineAsyncComponent(() => import('./forms/List.vue'))
		},
		select: {
			panel: {
				width: '70%',
				height: '500px'
			},
			config: {
				fields: {
					name: {
					},
					name_short: {
					},
					release_form: {
					},
					dosage: {
					},
					units: {
					},
					narcotic: {
					},
					pku: {
					}
				}
			}
		}
	}
}