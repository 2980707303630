import DBStore from "@/core/db_store";
import { deepMerge } from "@/helpers/utils";

export default class DBTableController {
	public store: DBStore;
	public form: any;
	public field: any;
	public stateField: any;
	public settings: any;

	constructor(protected props: any) {
		this.form = props.form;
		this.field = props.field;

		this.settings = {
			numbering: false,
			...props.config
		}

		this.store = this.createStore();
	}

	getFieldModel(fields: any, structure: any): any {
		const field: any = fields.shift();

		if (fields.length > 0) {
			return this.getFieldModel(fields, structure.fields[field].model);
		} else {
			return structure.fields[field];
		}
	}

	createStore() {
		const field = deepMerge({}, this.getFieldModel(this.field.split('.'), this.form.store.state));

		const store = new DBStore(field?.table ? field.table : `table_${this.field}`)
		store.model.subtable = true;

		this.stateField = this.getFieldModel(this.field.split('.'), this.form.store.state);

		store.createState({
			...this.stateField,
			...this.settings
		})

		const data = this.form.store.getValue(this.field);

		if (data) {
			store.data.rows = data;
		} else {
			const newData = this.form.store.setValue(this.field, []);

			store.data.rows = newData;
		}

		store.files = this.form.store.files;

		return store;
	}
}