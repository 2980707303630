import { defineAsyncComponent } from 'vue';

export default {
	forms: {
		edit: {
			component: defineAsyncComponent(() => import('./forms/Edit.vue'))
		},
		list: {
			component: defineAsyncComponent(() => import('./forms/List.vue'))
		},
		select: {
			panel: {
				width: '60%'
			},
			config: {
				fields: {
					id: {
						config: {
							visible: false
						}
					},
					name: {},
					time_start: {},
					time_end: {},
					active: {}
				}
			}
		}
	}
}