<script>
import { defineComponent, h, onMounted, onUnmounted, ref, resolveComponent } from 'vue';
import DBQuestionaryController from './controller';
import { genGUID } from '@/helpers/utils';

export default defineComponent({
	props: {
		form: {
			type: Object,
			default: null
		},
		field: {
			type: String,
			default: ''
		},
		label: {
			type: [Boolean, String],
			default: true
		},
		readonly: {
			type: Boolean,
			default: null
		},
		validation: {
			type: Boolean,
			default: true
		}
	},

	setup(props) {
		const DBEdit = resolveComponent('DBEdit');

		const controller = new DBQuestionaryController(props);

		if (!controller.structure) {
			console.error(`DBQuestionary: Не найдено поле(${controller.field.value})!`);

			return () => null
		}

		const validation = () => controller.validation();

		onMounted(() => {
			props.form.addValidation(validation);
		})

		onUnmounted(() => {
			props.form.delValidation(validation);
		})

		if (controller.structure.type == 'JSON') {
			const fields = controller.structure?.model?.fields;

			const inputs = {};

			const createInputs = (items, parent = null) => {
				for (const nameField in fields) {
					const item = {
						[nameField]: {
							...fields[nameField],
							items: {}
						}
					};

					const config = fields[nameField].config;

					if (parent) {
						if (config) {
							if (config?.parent == parent) {
								Object.assign(items, item);
							} else {
								if (items[config.parent]) {
									createInputs(items[config.parent].items, config.parent);
								}
							}
						}
					} else {
						if (config?.parent) {
							if (items[config.parent]) {
								createInputs(items[config.parent].items, config.parent);
							}
						} else {
							Object.assign(items, item);
						}
					}
				}
			}

			createInputs(inputs);

			return () => {
				const data = controller.data[props.field];

				const elements = [];

				elements.push(h(
					'div',
					{ class: "col-auto" },
					h('label', { class: "font-weight-bold", style: "margin-bottom: 0.25rem;" }, [controller.label])
				))

				if (fields) {
					const uncheck = (items) => {
						for (const item in items) {
							for (const field in fields) {
								if (item == field) {
									data[item] = fields[field].type == 'BOOLEAN' ? false : null;

									uncheck(items[item].items);
								}
							}
						}
					}

					const render = (items) => {
						for (const fieldName in items) {
							const input = items[fieldName];
							const config = input.config;

							const field = config?.field ? config.field : `${props.field}.${fieldName}`;

							if (config?.parent) {
								const _input = inputs[config.parent];

								if (_input?.config?.field) {
									if (_input.config.value) {
										if (controller.data[_input.config.field] != _input.config.value) continue;
									} else {
										if (!controller.data[_input.config.field]) continue;
									}
								} else {
									if (data && !data[config.parent]) continue;
								}
							}

							if (config?.label) {
								elements.push(h(
									'div',
									{ class: "col-auto" },
									h('label', { style: 'font-weight: 300;', class: { 'mb-1': true, 'font-weight-bold': !!config?.labelBold } }, config.label)
								))
							}

							if (input.type == 'BOOLEAN') {
								const id = `id_${genGUID()}`;

								if (config.field && config.value) {
									const enums = input.type?.enum ? input.type.enum : controller.store.state.fields[field].type.enum;

									elements.push(h(
										'div', { class: 'col-auto' },
										h(
											'div',
											{ class: 'form-check form-check-inline mb-1' },
											[
												h('input', {
													type: 'checkbox',
													class: 'form-check-input',
													id,
													checked: controller.data[field] == config.value,
													disabled: controller.readonly.value,
													onClick: (e) => {
														const checked = e.target.checked;
														if (checked) {
															for (const el of enums) {
																if (el.id == config.value) {
																	controller.data[field] = el.id;
																	controller.data[`_${field}`] = el.name;

																	return;
																}
															}
														}

														controller.data[field] = null;
														if (controller.data[`_${field}`]) delete controller.data[`_${field}`];
													},
													...(config?.props ? config.props : {})
												}),
												h('label', { class: 'form-check-label', for: id }, input.description)
											]
										)
									));
								} else {
									elements.push(h(
										'div',
										{ class: "col-auto", key: field },
										h(
											'div',
											{ class: 'form-check form-check-inline mb-1' },
											h(DBEdit, {
												form: props.form,
												field,
												onClick: (value) => {
													if (value) {
														const group = config.group;

														for (const field in fields) {
															if (fields[field]?.config?.group == group && field != fieldName) {
																if (fields[field]?.config?.field) {
																	const type = controller.store.state.fields[fields[field].config.field].type;

																	controller.data[fields[field].config.field] = type == 'BOOLEAN' ? false : null;
																} else {
																	data[field] = fields[field].type == 'BOOLEAN' ? false : null;
																}

																uncheck(items[field].items);
															}
														}
													} else {
														uncheck(input.items);
													}
												},
												...(config?.props ? config.props : {})
											})
										)
									))
								}

							} else if (config?.checksBoxes) {
								const enums = input.type?.enum ? input.type?.enum : controller.store.state.fields[field].type.enum;

								for (const item of enums) {
									const id = `id_${genGUID()}`;

									elements.push(h(
										'div', { class: 'col-auto' },
										h(
											'div',
											{ class: 'form-check form-check-inline mb-1' },
											[
												h('input', {
													type: 'checkbox',
													class: 'form-check-input',
													id,
													disabled: controller.readonly.value,
													checked: (input.type?.enum ? data[fieldName] : controller.data[field]) == item.id,
													onClick: (e) => {
														if (input.type?.enum) {
															if (e.target.checked) {
																data[fieldName] = item.id;
																data[`_${fieldName}`] = item.name;
															} else {
																data[fieldName] = null;
																if (data[`_${fieldName}`]) delete data[`_${fieldName}`];
															}
														} else {
															if (e.target.checked) {
																controller.data[field] = item.id;
																controller.data[`_${field}`] = item.name;
															} else {
																controller.data[field] = null;
																if (controller.data[`_${field}`]) delete controller.data[`_${field}`];
															}
														}
													},
													...(config?.props ? config.props : {})
												}),
												h('label', { class: 'form-check-label', for: id }, item.name)
											]
										)
									));
								}
							} else {
								elements.push(h(
									'div',
									{ class: config?.fullWidth ? "col mb-1" : "col-auto mb-1", key: field },
									h(DBEdit, {
										form: props.form,
										field,
										label: false,
										raw: true,
										placeholder: input.description,
										style: config?.style ? config.style : {},
										...(config?.props ? config.props : {})
									})
								))
							}

							if (config?.mark) {
								elements.push(h(
									'div',
									{ class: "col-auto" },
									h('label', { style: 'font-weight: 300;' }, config.mark)
								))
							}

							if (Object.keys(input.items).length > 0) render(input.items);
						}
					}

					render(inputs);

					if (controller.verified.value && controller.feedback.value) {
						elements.push(h('div', { class: "invalid-feedback", style: 'margin: -0.5rem 0 0.5rem 0.3rem !important;' }, controller.feedback.value));
					}
				}

				return h('div', { class: "form-row align-items-center" }, elements);
			}
		} else if (controller.structure.type == 'TEXT') {
			const config = controller.structure.config;

			return () => {
				const elements = [];

				if (typeof config?.props?.label == 'boolean' ? config.props.label : true) {
					elements.push(h(
						'label',
						{ class: "font-weight-bold" },
						controller.label
					))
				}

				elements.push(
					h(DBEdit, {
						form: props.form,
						field: props.field,
						label: false,
						raw: true,
						...(config?.props ? config.props : {})
					})
				);

				return h('div', { class: "form-group" }, elements);
			}
		} else {
			const config = controller.structure.config;

			return () => {
				const elements = [];

				elements.push(h(
					'div',
					{ class: "col-auto" },
					h('label', { class: "font-weight-bold mb-0" }, [controller.label])
				))

				elements.push(h(
					'div',
					{ class: config?.fullWidth ? "col" : "col-auto" },
					h(DBEdit, {
						form: props.form,
						field: config?.field ? config.field : props.field,
						label: false,
						raw: true,
						... (config?.props ? config.props : {})
					})
				))

				return h('div', { class: "form-row align-items-center pb-2" }, elements);
			}
		}
	}
})
</script>
