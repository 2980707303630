export default {
	forms: {
		list: {
			config: {
				fields: {
					id: {
						config: {
							visible: false
						}
					},
					description: {
					},
					document_type: {
					},
					contragent: {
					},
					key1: {
					},
					key2: {
					},
					value: {
					}
				}
			}
		},
		edit: {
			config: {
				fields: {
					id: {
						config: {
							visible: false
						}
					},
					description: {
					},
					document_type: {
					},
					contragent: {
					},
					key1: {
					},
					key2: {
					},
					value: {
					}
				}
			}
		}
	}
}