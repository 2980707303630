import api from "@/core/api";

export default {
	token(state: any, value: any) {
		if (value) {
			api.token.set(value);
		} else {
			api.token.clear();
		}

		state.token = value;
	},

	user(state: any, value: any) {
		state.user = value;
	},

	timeout(state: any, value: any) {
		state.timeout = value;
	},

	messages(state: any, value: any) {
		state.messages = value;
	},

	online(state: any, value: any) {
		state.online = value;
	},

	onlineAgent(state: any, value: any) {
		state.onlineAgent = value;
	},

	locale(state: any, value: any) {
		state.locale = value
	},

	loaded(state: any, value: any) {
		state.loaded = value;
	},

	openDocument(state: any, value: any) {
		state.openDocument = value;
	},

	constants(state: any, value: any) {
		state.constants = value;
	},

	cbPhoneNumber(state: any, value: any) {
		state.cbPhoneNumber = value;
	},

	equipment(state: any, value: any) {
		state.equipment.splice(0, state.equipment.length);

		value.forEach((el: any) => state.equipment.push(el));
	},

	channels(state: any, value: any) {
		state.channels = value.split('|');
	}
}
