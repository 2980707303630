import axios from "axios";
import LocalStorageToken from "@/helpers/LocalStorageToken";
import { toastError } from "@/helpers/toastify";
import { config } from "@/config";
import stateStore from "@/store";

let showerror = true;

class API {
	public token: LocalStorageToken;

	constructor() {
		this.token = new LocalStorageToken("token");

		axios.interceptors.response.use((response) => response, (error) => error.response);
	}

	getHeaders() {
		const token = this.token.get();

		if (!token) return {};

		return {
			authorization: token,
			"Access-Control-Allow-Origin": "*"
		};
	}

	/**
	 * Запрос к серверу
	 * @param path 
	 * @param opts 
	 * @param toast 
	 * @returns 
	 */
	query(path: string, opts: any = {}, toast = true) {
		stateStore.state.load = true;

		opts.timeout = 5 * 60 * 1000;

		opts.headers = {
			...this.getHeaders(),
			...opts.headers,
		};

		opts.url = `${config.api}${path}`;

		const showError = (message: any) => {
			if (showerror && toast) {
				showerror = false;

				toastError(message);
			}
		}

		return new Promise((resolve, reject) => {
			axios(opts)
				.then(response => {
					if (response && response.status) {
						if (response.status == 200 || response.status == 201) {
							showerror = true;

							resolve(response.data);
						} else {
							response.data.message = response.data && response.data.message ? response.data.message : `Ошибка сервера: ${response.status} ${response.statusText}`;

							showError(response.data.message);

							reject(response.data.message);
						}
					} else {
						reject('Ошибка подключения к серверу!');
					}
				})
				.catch((response) => {
					console.error(response);

					showError(response.message);

					reject(response.message);
				})
				.finally(() => {
					stateStore.state.load = false;
				})
		});
	}
}

export default new API();
